import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'

const Thanks = ({ data }) => (
  <Layout pageData={data.strapiContact}>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Dank U!</h1>
          <p>Uw bericht is verstuurd, reactie zal zo spoedig mogelijk volgen.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Thanks

export const query = graphql`
  query {
    strapiContact {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`